<template lang="pug">
RoundedContainer
  div(v-if="product.allergens || app?.pdfFileAllergens")
    div.heading.d-flex.align-center.mb-5.mt-4
      div.text-h6 {{ $t('productBuilder.allergens') }}
      a.text-decoration-underline.ms-auto.text-primary(v-if="app?.pdfFileAllergens" :href="app.pdfFileAllergens" target="_self") {{ $t("productBuilder.infoAllergens") }}
    div.d-flex.flex-wrap.gap-1(v-if="product.allergens")
      AllergenChip.flex-grow-0(v-for="allergen in product.allergens" :key="allergen.code" :allergen="allergen")

  div(v-if="ingredients")
    div.heading.d-flex.align-center.mb-5.mt-4
      div.text-h6 {{ $t('productBuilder.ingredients') }}
    div {{ ingredients }}
  
  div(v-if="product.tags && product.tags.length")
    div.heading.d-flex.align-center.mb-5.mt-4
      div.text-h6 {{ $t("productBuilder.tags") }}
    div.tags-container.d-flex
      div.border.rounded-sm.pa-2.mb-2.me-2(v-for="tag, i in product.tags" :key="i") {{ tag }}
</template>

<script setup lang="ts">
import AllergenChip from "@/components/AllergenChip.vue";
import type { Product } from "@/models";
import { useApp } from "@/store/app";
import { computed, type PropType } from "vue";

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true
  }
});

const { app } = useApp();
const ingredients = computed(() => {
  return props.product.standardComponents.map(component => component.name).join(" ,");
});
</script>

<style lang="scss" scoped></style>
