<template lang="pug">
RoundedContainer(
  v-for="attribute, i in product.articleAttributes" 
  :key="i" :title="attribute.name" 
  :subtitle="attribute.fieldType == 'string' ? $t('productBuilder.articleAttributes.subtitle') : null" 
  :badge="attribute.isMandatory ? $t('general.mandatory') : null" 
  :stickyTitle="true" 
  :horizontalPadding="false"
)

  StringArticleAttributeSelector(
    v-if="isStringArticleAttribute(attribute)"
    :attribute="attribute"
    v-model="articleAttributes[attribute.attributeTypeId]"
  )

  EatToggleButton.w-100.mb-2.px-5.gap-4(v-if="attribute.fieldType == 'boolean'" v-model="articleAttributes[attribute.attributeTypeId].value" :class="{ 'bg-selected': articleAttributes[attribute.attributeTypeId].value }" v-slot="{ on }")
    div {{ $t('general.yesNo') }}
    EatToggleIcon.ms-auto(:on="on" :size="18" onIcon="$check" offIcon="$plus")

  NumericArticleAttributeSelector.mb-2.px-5(
    v-if="attribute.fieldType == 'number'"
    :attribute="attribute"
    v-model="articleAttributes[attribute.attributeTypeId]"
  )
</template>

<script setup lang="ts">
import { computed, type PropType } from "vue";
import type { Product } from "@/models";
import StringArticleAttributeSelector from "@/components/menuProduct/selectors/StringArticleAttributeSelector.vue";
import NumericArticleAttributeSelector from "@/components/menuProduct/selectors/NumericArticleAttributeSelector.vue";
import type { ArticleAttribute, ProductConfiguration, StringArticleAttribute } from "@/models/Product";

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true
  },
  modelValue: {
    type: Object as PropType<ProductConfiguration>,
    required: true
  }
});

const emit = defineEmits(["update:modelValue"]);

const isStringArticleAttribute = (attribute: ArticleAttribute): attribute is StringArticleAttribute => attribute.fieldType == 'string';

const articleAttributes = computed({
  get: () => props.modelValue.articleAttributes,
  set: value => emit("update:modelValue", value)
});
</script>
