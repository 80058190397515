<template lang="pug">
div
  RoundedContainer(
    v-for="module, i in product.modules" :key="module.name+i" 
    :title="module.name" 
    :subtitle="readonly ? '' : formatMaxAndMinCustomerChoices(module)" 
    :badge="module.minCustomerChoices && module.minCustomerChoices > 0 ? $t('general.mandatory') : ''" 
    :stickyTitle="true"
    :horizontalPadding="false"
  )
    EatUl
      template(v-for="moduleProduct, i in module.products" :key="moduleProduct.name+i" )
        EatLi.list-item.px-5.py-3.cursor-pointer(:padding="false" v-if="isSelected(module.name, moduleProduct) || !showUpselling(module)") 
          div.product-image__container(@click="readonly ? null : onModuleProductClick(module, moduleProduct)")
            EatImage.image.rounded-sm(:src="getImageSrc(moduleProduct)")

          div.ps-3(@click="readonly ? null : onModuleProductClick(module, moduleProduct)")
            div.text-black.pe-3 {{ moduleProduct.name }}
            div(v-if="moduleProduct.priceVariation && !hidePrices" style="font-size:0.875rem;color:rgba(0, 0, 0, 0.6);line-height:1.2") {{ formatPrice(moduleProduct.priceVariation) }}
            div.d-flex.flex-wrap.gap-1.mt-2(v-if="moduleProduct.allergens")
              AllergenChip.flex-grow-0(v-for="allergen in moduleProduct.allergens" :key="allergen.code" :allergen="allergen")

          EatStepper(
            v-if="!readonly"
            :small="true"
            @decrease="removeModuleProduct(module.name, moduleProduct, module)" 
            @increase="addModuleProduct(module.name, moduleProduct, module)" 
            :value="getModuleProductQuantity(module.name, moduleProduct)"
            :transparent="true" 
            :disabled="isModuleProductStepperDisabled(module, moduleProduct)"
          )

    EatUl(v-if="showUpselling(module) && !readonly")
      div.text-h6.my-3.px-5.text-black {{ $t('productBuilder.upselling.title') }}
      template(v-for="moduleProduct, i in module.products" :key="moduleProduct.name+i")
        EatLi.list-item.px-5.py-3(v-if="isProductEligibleForUpselling(module.name, moduleProduct)")
          div.product-image__container.rounded-sm(@click="onUpsellingProductClick(module, moduleProduct)") 
            EatImage.image.rounded-sm(:src="getImageSrc(moduleProduct)")
          div.ps-3(@click="onUpsellingProductClick(module, moduleProduct)")
            div.text-black.pe-3 {{ moduleProduct.name }}
            div(v-if="moduleProduct.priceUpselling && !hidePrices" style="font-size:0.875rem;color:rgba(0, 0, 0, 0.6);line-height:1.2") {{ formatPrice(moduleProduct.priceUpselling) }}
            div.d-flex.flex-wrap.gap-1.mt-2(v-if="moduleProduct.allergens")
              AllergenChip.flex-grow-0(v-for="allergen in moduleProduct.allergens" :key="allergen.code" :allergen="allergen")
          
          EatStepper(
            v-if="!readonly"
            @decrease="removeUpsellingProduct(module.name, moduleProduct)" 
            @increase="addUpsellingProduct(module.name, moduleProduct)" 
            :value="getUpsellingModuleQuantity(module.name, moduleProduct)"
            :transparent="true" 
          )
</template>

<script setup lang="ts">
import { computePrice, type Module, type ModuleProduct } from "@/models/Product";
import type { Product } from "@/models";
import ProductBuilderSelect from "@/components/menuProduct/selectors/ProductBuilderSelect.vue";
import MandatoryComponentsSelector from "@/components/menuProduct/selectors/MandatoryComponentsSelector.vue";
import StandardComponentsList from "@/components/menuProduct/selectors/StandardComponentsList.vue";
import RemovableComponentsSelector from "@/components/menuProduct/selectors/RemovableComponentsSelector.vue";
import AddableComponentsSelector from "@/components/menuProduct/selectors/AddableComponentsSelector.vue";
import AllergenChip from "@/components/AllergenChip.vue";
import { useApp } from "@/store/app";
import { formatPrice } from "@/utils/formatPrice";
import { computed, type PropType } from "vue";
import { useI18n } from "vue-i18n";
import useProductDetail from "@/store/order/productDetail";
import { setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import { useCartItems } from "@/store/cart/cartItems";

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true
  },
  isCartModifier: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  modules: {
    type: Object as PropType<{ [key: string]: ModuleProduct[] }>,
    default: null
  },
  upsellingModules: {
    type: Object as PropType<{ [key: string]: ModuleProduct[] }>,
    default: null
  },
  hidePrices: {
    type: Boolean,
    required: true
  }
});

const emit = defineEmits(["input-modules", "input-upselling-modules"])

const { location } = useApp();
const i18n = useI18n();
const { flattenedProductsInCart } = useCartItems();
const modules = computed({
  get: () => {
    return props.modules;
  },
  set: value => {
    emit("input-modules", value);
  }
});

const upsellingModules = computed({
  get: () => {
    return props.upsellingModules;
  },
  set: value => {
    emit("input-upselling-modules", value);
  }
});

const isSelected = (moduleName: string, moduleProduct: ModuleProduct) => {
  if (!modules.value || !modules.value[moduleName]) return false;
  return modules.value[moduleName].filter(product => product.id == moduleProduct.id).length > 0;
};

const showUpselling = (module: Module) => {
  if (!modules.value || !modules.value[module.name]) return false;
  if (modules.value[module.name].length < module.maxCustomerChoices) return false;
  return module.products.some(
    product =>
      product.priceUpselling &&
      !modules.value[module.name].some(moduleProduct => product.id === moduleProduct.id)
  );
};

const formatMaxAndMinCustomerChoices = (module: Module) => {
  const min = module.minCustomerChoices;
  const max = module.maxCustomerChoices;
  if (min && !max)
    return i18n.t("productBuilder.moduleProducts.subtitle.atLeast",{ quantity: min });
  if (!min && max)
    return i18n.t("productBuilder.moduleProducts.subtitle.upTo", { quantity: max });
  if (min && max && min === max)
    return i18n.t("productBuilder.moduleProducts.subtitle.mandatoryQuantity", {
      quantity: max
    });
  if (min && max && min !== max)
    return i18n.t("productBuilder.moduleProducts.subtitle.fromUpTo", { from: min, upTo: max });
  return "";
};

const getModuleProductQuantity = (moduleName: string, moduleProduct: ModuleProduct) => {
  if (!modules.value || !modules.value[moduleName]) return 0;
  return modules.value[moduleName].filter(product => product.id == moduleProduct.id).length;
};

const isModuleProductStepperDisabled = (module: Module, moduleProduct: ModuleProduct) => {
  if (modules.value[module.name].length >= module.maxCustomerChoices) {
    return !modules.value[module.name].some(
      configProduct => configProduct.id === moduleProduct.id
    );
  }
};

const { canAddToConfiguration } = useProductDetail();
const addModuleProduct = (moduleName: string, moduleProduct: ModuleProduct, module: Module) => {
  if (!modules.value || !modules.value[moduleName]) return;
  if (moduleProduct.priceVariation && !canAddToConfiguration(moduleProduct.priceVariation)) {
    setSnackbar(
      i18n.t("productBuilder.negativePriceNotAllowed").toString(),
      TypeSnackbar.WARNING
    );
    return;
  }
  if (
    !module.maxCustomerChoices ||
    modules.value[moduleName].length < module.maxCustomerChoices
  ) {
    if (!moduleProduct.warehouseItem) {
      modules.value[moduleName].push(moduleProduct);
    } else {
      const cartQuantity = flattenedProductsInCart.value[moduleProduct.id] || 0;
      const localQuantity = modules.value[moduleName].filter(
        configModule => configModule.id === moduleProduct.id
      ).length;
      const totalQuantity = props.isCartModifier ? cartQuantity : cartQuantity + localQuantity;
      if (totalQuantity < moduleProduct.warehouseItem.availableStockQuantity) {
        modules.value[moduleName].push(moduleProduct);
      } else {
        setSnackbar(
          i18n.t("order.product.notAvailableInStock").toString(),
          TypeSnackbar.WARNING
        );
      }
    }
  }
};

const removeModuleProduct = (
  moduleName: string,
  moduleProduct: ModuleProduct,
  module: Module
) => {
  if (!modules.value || !modules.value[moduleName]) return;
  if (moduleProduct.priceVariation && !canAddToConfiguration(-moduleProduct.priceVariation)) {
    setSnackbar(
      i18n.t("productBuilder.negativePriceNotAllowed").toString(),
      TypeSnackbar.WARNING
    );
    return;
  }
  const index = modules.value[moduleName].findIndex(product => product.id === moduleProduct.id);
  if (index > -1) {
    modules.value[moduleName].splice(index, 1);
  }
  if (
    !showUpselling(module) &&
    upsellingModules.value[moduleName] &&
    upsellingModules.value[moduleName].length
  )
    upsellingModules.value[moduleName] = [];
};

const getUpsellingModuleQuantity = (moduleName: string, moduleProduct: ModuleProduct) => {
  if (!upsellingModules.value || !upsellingModules.value[moduleName]) return 0;
  return upsellingModules.value[moduleName].filter(product => product.id == moduleProduct.id)
    .length;
};

const onModuleProductClick = (module: Module, moduleProduct: ModuleProduct) => {
  if (
    getModuleProductQuantity(module.name, moduleProduct) === 0 &&
    !isModuleProductStepperDisabled(module, moduleProduct)
  ) {
    addModuleProduct(module.name, moduleProduct, module);
  } else {
    setSnackbar(
      i18n.t("order.product.maxModuleQuantityReached").toString(),
      TypeSnackbar.WARNING
    );
  }
};

const isProductEligibleForUpselling = (moduleName: string, moduleProduct: ModuleProduct) => {
  if (!modules.value || !modules.value[moduleName]) return false;
  return (
    moduleProduct.priceUpselling &&
    !modules.value[moduleName].some(configProduct => moduleProduct.id === configProduct.id)
  );
};

const addUpsellingProduct = (moduleName: string, moduleProduct: ModuleProduct) => {
  if (!upsellingModules.value || !upsellingModules.value[moduleName]) return 0;
  if (moduleProduct.priceVariation && !canAddToConfiguration(moduleProduct.priceVariation)) {
    setSnackbar(
      i18n.t("productBuilder.negativePriceNotAllowed").toString(),
      TypeSnackbar.WARNING
    );
  } else {
    if (!moduleProduct.warehouseItem) {
      upsellingModules.value[moduleName].push(moduleProduct);
    } else {
      const cartQuantity = flattenedProductsInCart.value[moduleProduct.id] || 0;
      const localQuantity = upsellingModules.value[moduleName].filter(
        configModule => configModule.id === moduleProduct.id
      ).length;
      if (cartQuantity + localQuantity < moduleProduct.warehouseItem.availableStockQuantity) {
        upsellingModules.value[moduleName].push(moduleProduct);
      } else {
        setSnackbar(
          i18n.t("order.product.notAvailableInStock").toString(),
          TypeSnackbar.WARNING
        );
      }
    }
  }
};

const removeUpsellingProduct = (moduleName: string, moduleProduct: ModuleProduct) => {
  if (!upsellingModules.value || !upsellingModules.value[moduleName]) return 0;
  if (moduleProduct.priceVariation && !canAddToConfiguration(-moduleProduct.priceVariation)) {
    setSnackbar(
      i18n.t("productBuilder.negativePriceNotAllowed").toString(),
      TypeSnackbar.WARNING
    );
    return;
  }
  const index = upsellingModules.value[moduleName].findIndex(
    product => product.id === moduleProduct.id
  );
  if (index > -1) {
    upsellingModules.value[moduleName].splice(index, 1);
  }
};

const onUpsellingProductClick = (module: Module, moduleProduct: ModuleProduct) => {
  if (getUpsellingModuleQuantity(module.name, moduleProduct) === 0) {
    addUpsellingProduct(module.name, moduleProduct);
  }
};

const getImageSrc = (product: ModuleProduct) => {
  return product.images[0] || location.value?.logoUrl;
};
</script>

<style lang="scss" scoped>
.list-item {
  display: grid;
  grid-template-columns: 64px auto 76px;
  align-items: center;
  .image {
    width: 64px;
  }
}
</style>
