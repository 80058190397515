<template lang="pug">
div.container.categories-container
    div.category.rounded-sm.px-5.py-2.mb-8(v-for="category, i in categories" :key="i" @click="onCategoryClick(category.id)") 
        div.category-image__container.rounded-sm
          EatImage.category-image.rounded-sm(:src="getImageSrc(category)")
        div.text-body-1.font-weight-medium.mt-3 {{ category.name }}
</template>

<script setup lang="ts">
import type { Category } from "@/models";
import { Saletype } from "@/models/App";
import { useApp } from "@/store/app";
import { useMenu, useMenuFilters } from "@/store/menu";
import { nextTick, onMounted, type PropType } from "vue";

const props = defineProps({
  saletype: {
    type: String as PropType<Saletype>,
    required: true
  }
});

const emit = defineEmits(["close"]);
  
const { location } = useApp();
const { categories, fetchMenu } = useMenu(props.saletype);
const { onSearchTextChange, onSelectedTagsChange } = useMenuFilters();

onMounted(async () => {
  await fetchMenu();
});

const getImageSrc = (category: Category) => {
  return category.image || location.value?.logoUrl || "";
};

const onCategoryClick = (id: string) => {
  onSelectedTagsChange([]);
  onSearchTextChange("");
  nextTick(() => {
    emit("close", id);
  });
};
</script>

<style lang="scss" scoped>
.categories-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  .category {
    cursor: pointer;
  }
  .category-image__container {
    width: 100%;
  }
}
</style>
