<template lang="pug">
div.top-bar__container.bg-white(:key="$route.fullPath")
  div.top-bar(v-if="location" ref="$topBar")
    div.top-bar__content.border-b.px-4.py-4.d-flex.align-center
      div.logo__container.rounded-lg.flex-shrink-0.overflow-hidden
        EatImage.logo.rounded-lg(:src="location.logoUrl")
      div.location-name.ps-5.overflow-hidden
        h1.text-h6.font-weight-medium.text-truncate {{ location.businessName }}
      div.top-bar__call-waiter.px-3.d-flex.ms-auto.me-14(v-if="cartPrefix === CartPrefix.TABLE")
        EatIconBtn.text-black(icon="$waiter" @click="showMoreDialog = true") 
      EatSearchBar.top-bar__search-bar(
        icon="$search" 
        @blur="closeSearchbar"
        :modelValue="searchText" 
        @update:modelValue="onSearchTextChange"
        :placeholder="$t('order.topBar.search')" 
        :expandable="true"
      )
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import EatSearchBar from "@/components/utils/EatSearchBar.vue";
import { useMenuFilters } from "@/store/menu";
import { CartPrefix } from "@/models/Cart";
import { useCart } from "@/store/cart";
import { useShowMoreDialog } from "@/store/layout/moreDialog";
import { useApp } from "@/store/app";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const showSearchBar = ref(false);
const { searchText, onSearchTextChange } = useMenuFilters();
const { cartPrefix } = useCart();
const { location } = useApp();

const showMoreDialog = useShowMoreDialog();

const openSearchBar = () => {
  showSearchBar.value = true;
};

const closeSearchbar = () => {
  showSearchBar.value = false;
};

const $topBar = ref<HTMLElement>();
const scroller = ref<HTMLElement | null>(null);
const trigger = ref<HTMLElement | null>(null);
const endScrollTrigger = ref(0);

let scrollTrigger: ScrollTrigger | undefined;
const setupAnimation = () => {
  if (scrollTrigger) scrollTrigger.kill();
  const timeline = gsap
    .timeline({ paused: true })
    .to($topBar.value as Element, { opacity: 1, paddingBottom: 64 });

  scroller.value = document.querySelector("[id$=order-scrollable");
  trigger.value = document.querySelector("[id$=order-scroll-trigger]");

  endScrollTrigger.value = trigger.value?.getBoundingClientRect().bottom || 0;

  scrollTrigger = ScrollTrigger.create({
    scroller: scroller.value,
    trigger: trigger.value,
    start: () => `top top`,
    end: `${endScrollTrigger.value}px top`,
    fastScrollEnd: true,
    animation: timeline,
    scrub: 0.1,
    invalidateOnRefresh: true
  });
};

onMounted(() => {
  setTimeout(() => {
    setupAnimation();
  }, 300);
});
</script>

<style lang="scss" scoped>
.top-bar {
  position: relative;
  padding-bottom: 0;
  opacity: 0;
}
.top-bar__content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  z-index: 2;
  .location-name {
    position: relative;
  }

  .logo__container {
    width: 44px;
    background: rgb(197, 245, 49);
    background: linear-gradient(226deg, rgba(197, 245, 49, 1) 10%, rgba(0, 113, 242, 1) 92%);
    .logo {
      border: 1px solid transparent;
    }
  }

  .top-bar__search-bar {
    position: absolute;
    top: 50%;
    right: 16px;
    max-width: calc(100% - 32px);
    transform: translateY(-50%);
    z-index: 3;
  }
}

.grow-left-fade-enter-active,
.grow-left-fade-leave-active {
  transition: all 0.2s ease;
  transform: scaleX(1);
}

.grow-left-fade-enter-from,
.grow-left-fade-leave-to {
  opacity: 0;
  transform: scaleX(0);
}
</style>
