import { nextTick, onMounted, type Ref, ref, watch } from "vue";
import gsap from "gsap";
import type { Category } from "@/models";
import { Saletype } from "@/models/App";

export const useCategoriesScrollSync = (
  categories: Ref<Category[]>,
  saletype: Saletype
) => {
  const listScroller: Ref<HTMLElement | null> = ref(null);
  const navigationScroller: Ref<HTMLElement | null> = ref(null);
  const intersectionObserver = ref<IntersectionObserver | null>(null);
  const observables = ref<HTMLElement[]>([]);

  const visibleElements = ref<HTMLElement[]>([]);
  const wannabeActive = ref("");
  const activeCategory = ref("");

  const activateNavigationLink = (categoryId: string) => {
    activeCategory.value = categoryId;
    const offsetLeft = document.getElementById(`btn-${saletype}-${categoryId}`)?.offsetLeft;
    if (offsetLeft === undefined || !navigationScroller.value) return;

    gsap.to(navigationScroller.value as Element, {
      duration: 0.3,
      scrollTo: { x: offsetLeft - 56 }
    });
  };

  const isElementVisible = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 100 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
  };

  const scrollToCategory = (categoryId: string) => {
    const element = observables.value.find(
      element => element.id.replace(`${saletype}-`, "") === categoryId
    );
    if (element === undefined || !listScroller.value) return;

    activeCategory.value = categoryId;
    if (!isElementVisible(element)) wannabeActive.value = categoryId;

    const offsetTop = element.offsetTop;
    const scrollOffset = getScrollOffset();
    gsap.to(listScroller.value as Element, {
      duration: 0.3,
      scrollTo: { y: offsetTop + scrollOffset }
    });
  };

  let timer: number | null = null;
  const endScrollHandler = () => {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => (wannabeActive.value = ""), 500) as unknown as number;
  };

  let ticking = false;
  const scrollHandler = () => {
    if (!ticking) {
      window.requestAnimationFrame(() => (endScrollHandler(), (ticking = false)));
      ticking = true;
    }
  };

  const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      const categoryId = entry.target.id.replace(`${saletype}-`, "") as string;
      if (!entry.isIntersecting || (wannabeActive.value && wannabeActive.value !== categoryId))
        return;
      activateNavigationLink(categoryId);
    });
  };

  const updateObservables = (categories: Category[]) => {
    if (!intersectionObserver.value || !categories.length) return;

    observables.value.forEach(observable => intersectionObserver.value?.unobserve(observable));
    observables.value = [];
    visibleElements.value = [];
    wannabeActive.value = "";

    for (const category of categories) {
      const observable = document.getElementById(`${saletype}-${category.id}`);
      if (observable) {
        observables.value.push(observable);
        intersectionObserver.value?.observe(observable);
      }
    }

    if (categories.some(category => category.id === activeCategory.value)) {
      nextTick(() => scrollToCategory(activeCategory.value));
    } else {
      activeCategory.value = categories[0]?.id.replace(`${saletype}-`, "") || "";
    }
  };

  const getScrollOffset = () => {
    const scrollerRect = listScroller.value?.getBoundingClientRect();
    const navigationRect = navigationScroller.value?.getBoundingClientRect();
    if(!scrollerRect || !navigationRect) return 0;
    return scrollerRect.top - navigationRect.bottom - 10;
  }

  onMounted(async () => {
    await nextTick()
    listScroller.value = document.getElementById(`${saletype}-order-scrollable`);
    navigationScroller.value = document.getElementById(`${saletype}-category-slider-scrollable`);
    if (!listScroller.value || !navigationScroller.value) return;

    gsap.to(listScroller.value as Element, { scrollTo: { y: 0 } });

    const options = {
      root: listScroller.value,
      rootMargin: `-164px 0px -${(listScroller.value.clientHeight || 0) / 2}px 0px`
    };
    intersectionObserver.value = new IntersectionObserver(intersectionCallback, options);
    updateObservables(categories.value);
    listScroller.value?.addEventListener("scroll", scrollHandler, false);
  });

  watch(categories, async (value) => {
    await nextTick();
    await nextTick();
    updateObservables(value);
  });

  const isActiveCategory = (id: string) => id === activeCategory.value;

  return {
    scrollToCategory,
    isActiveCategory
  };
};
