<template lang="pug">
RoundedContainer(:title="$t('productBuilder.addableComponents.title')" :subtitle="readonly ? '' : $t('productBuilder.addableComponents.subtitle')" :horizontal-padding="false" :stickyTitle="true")
  ProductBuilderSelect(:options="components" :selected="selected" @change="onSelectChange" :readonly="readonly")
    template(v-slot:option="{ option, isSelected }")
      div.d-flex.justify-space-between.align-center(v-if="isAddableComponent(option)")
        div {{ option.name }}
        div(v-if="option.addingPrice != 0 && !hidePrices")
          span +{{ formatPrice(option.addingPrice) }}
        EatIcon(v-else icon="$plus" :size="18" :class="{ 'text-primary': isSelected && !readonly }") 
</template>

<script setup lang="ts">
import { computed, onMounted, type PropType, type Ref, ref } from "vue";
import ProductBuilderSelect from "@/components/menuProduct/selectors/ProductBuilderSelect.vue";
import type { AddableComponent } from "@/models/Product";
import { formatPrice } from "@/utils/formatPrice";
import useProductDetail from "@/store/order/productDetail";
import { setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import { useI18n } from "vue-i18n";

const props = defineProps({
  components: {
    type: Array as PropType<AddableComponent[]>,
    required: true
  },
  readonly: {
    type: Boolean,
    required: true
  },
  hidePrices: {
    type: Boolean,
    required: true
  },
  modelValue: {
    type: Array as PropType<AddableComponent[]>,
    required: true
  }
});

const emit = defineEmits(["update:modelValue"]);

const isAddableComponent = (component: any): component is AddableComponent => true;

const i18n = useI18n();
const localValue = computed({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value)
});

const selected: Ref<number[]> = ref([]);

onMounted(() => {
  if (props.modelValue.length) {
    props.components.forEach((component, i) => {
      if (
        props.modelValue.some(configComponent => configComponent.productId === component.productId)
      )
        selected.value.push(i);
    });
  }
});

const onSelectChange = (index: number) => {
  const { canAddToConfiguration } = useProductDetail();
  const newSelected = props.components[index];
  if (selected.value.includes(index)) {
    if (!canAddToConfiguration(-newSelected.addingPrice)) {
      setSnackbar(
        i18n.t("productBuilder.negativePriceNotAllowed").toString(),
        TypeSnackbar.WARNING
      );
      return;
    }
    selected.value.splice(selected.value.indexOf(index), 1);
  } else {
    if (!canAddToConfiguration(newSelected.addingPrice)) {
      setSnackbar(
        i18n.t("productBuilder.negativePriceNotAllowed").toString(),
        TypeSnackbar.WARNING
      );
      return;
    }
    selected.value.push(index);
  }
  localValue.value = props.components.filter((component, i) => selected.value.includes(i));
};
</script>
