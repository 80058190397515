<template lang="pug">
EatNumberInput.mb-2.px-5(
    v-model="localValue",
    :label="$t('productBuilder.articleAttributes.numberAttributeLabel')"
    :min-value="attribute.isMandatory ? 1 : 0"
    :border="false"
)
</template>

<script setup lang="ts">
import EatNumberInput from "@/components/inputs/EatNumberInput.vue";
import type { ArticleAttribute, ConfigurationArticleAttribute } from "@/models/Product";
import type { PropType } from "vue";
import { computed } from "vue";

const props = defineProps({
    attribute: {
        type: Object as PropType<ArticleAttribute>,
        required: true
    },
    modelValue: {
        type: Object as PropType<ConfigurationArticleAttribute>,
        required: true
    }
});

const emit = defineEmits(['update:modelValue']);

const localValue = computed({
    get: () => props.modelValue.value as number,
    set: (value: number) => emit('update:modelValue', { ...props.modelValue, value })
})
</script>