<template lang="pug">
div.bg-neutral-a.overflow-hidden.pb-1(:class="cartPrefix === CartPrefix.RESTAURANT ? 'rounded-xxl' : 'rounded-t-xxl'")
  div.cover-image__container.bg-white
    a.btn-back-multilocation.bg-primary.rounded-lg.d-flex.align-center.py-2.ps-2.pe-4(:href="multilocationUrl" v-if="multilocationUrl && cartPrefix === CartPrefix.RESTAURANT")
      EatIcon.text-white(icon="$back" size="18px")  
      div.ps-2.text-white.font-weight-medium {{ $t("order.action.multilocationButton") }}
    LanguageDropDownMenu.language-selector.bg-white(v-if="cartPrefix !== CartPrefix.RESTAURANT")
    EatImage.cover-image(:src="app?.coverImageUrl" aspectRatio="16/9")
    div.logo__container.rounded-lg.flex-shrink-0.overflow-hidden
      EatImage.logo.rounded-lg(:src="location?.logoUrl")
  div.bg-white
    div.container
      div.location-info(v-if="location")
        h1.text-h5.font-weight-medium {{ location.businessName }}
        div.subtitle-2.mt-2 {{ fullAddress(location) }}
        div.text-primary.subtitle-2.pb-3 {{ isLocationOpenToday ? $t("location.open") + " " + todaysOpeningHours : $t("location.closed") }}

      div.contacts.d-flex.py-3(v-if="location")
        div.d-flex.flex-grow-1(v-if="location.phone")
          a.contacts-link.rounded.border.flex-grow-1.text-center.py-1(:href="`tel:${location.phone}`") {{ $t('location.action.phone') }}
        div.d-flex.flex-grow-1(v-if="location.whatsapp")
          a.contacts-link.rounded.border.flex-grow-1.text-center.py-1(:href="location.whatsapp") {{ $t('location.action.whatsapp') }}
        div.d-flex.flex-grow-1(v-if="location.email")
          a.contacts-link.rounded.border.flex-grow-1.text-center.py-1(:href="`mailto:${location.email}`") {{ $t('location.action.email') }}
</template>

<script setup lang="ts">
import LanguageDropDownMenu from "@/components/LanguageDropDownMenu.vue";
import { fullAddress } from "@/models/AppLocation";
import { CartPrefix } from "@/models/Cart";
import { useApp } from "@/store/app";
import { useCart } from "@/store/cart";
import useOpeningHours from "@/store/openingHours";
import { computed } from "vue";

const { app, location } = useApp();
const { cartPrefix } = useCart();

const multilocationUrl = computed(() => {
  return app.value?.appMasterUrl ? app.value.appMasterUrl + "/multilocation" : "";
});

const { todaysOpeningHours, isLocationOpenToday } = useOpeningHours(
  location.value?.openingHours
);
</script>

<style lang="scss" scoped>
.cover-image__container {
  position: relative;
  padding-bottom: 60px;
  .cover-image {
    background: rgb(197, 245, 49);
    background: linear-gradient(226deg, rgba(197, 245, 49, 1) 10%, rgba(0, 113, 242, 1) 92%);
    width: 100%;
    object-fit: cover;
  }

  .logo__container {
    width: 112px;
    height: 112px;
    position: absolute;
    left: 20px;
    bottom: -56px;
    transform: translateY(-50%);
    background: rgb(197, 245, 49);
    background: linear-gradient(226deg, rgba(197, 245, 49, 1) 10%, rgba(0, 113, 242, 1) 92%);
    .logo {
      border: 1px solid transparent;
    }
  }
  .btn-back-multilocation {
    position: absolute;
    left: 16px;
    top: 16px;
  }

  .language-selector {
    position: absolute;
    right: 16px;
    top: 16px;
  }
}
.menu-content {
  border: 1px solid #b2c0cd;
  width: 200px;
}

.contacts {
  gap: 8px;
}
</style>
