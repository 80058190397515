<template lang="pug">
EatUl
  EatLi.cursor-pointer(v-for="option, i in options" :padding="padding" :positionRelative="positionRelative" :border-bottom="i !== options.length - 1" :key="i" @click="readonly ? null : onOptionClick(i)" :class="{ 'bg-selected': selected.includes(i) && !readonly }")
    slot(name="option" v-bind="{ option, index: i, isSelected: selected.includes(i) }")
</template>

<script setup lang="ts">
import type {
  AddableComponent,
  StringArticleAttribute,
  MandatoryComponent,
  ProductComponent
} from "@/models/Product";
import type { PropType } from "vue";

const props = defineProps({
  options: {
    type: Array as PropType<
      Array<ProductComponent| MandatoryComponent| AddableComponent| StringArticleAttribute>
    >,
    required: true
  },
  padding: {
    type: Boolean,
    default: true
  },
  positionRelative: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  selected: {
    type: Array as PropType<number[]>,
    default: () => []
  }
});

const emit = defineEmits(["change"]);

const onOptionClick = (index: number) => {
  if (!props.readonly) emit("change", index);
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: var(--bgselected);
  color: var(--clr-primary) !important;
}
</style>
