<template lang="pug">
FullPageDialog(v-model="showDialog")
  template(v-slot:header)
    div.d-flex.flex-column(style="width: 100%")
      div.d-flex.align-center.pa-2.bg-white
        EatIconBtn.me-4.ms-3(icon="$close" :size="16" @click="onCloseDialog")
        div.product-name.text-body-1.font-weight-medium.text-truncate.pe-6(v-if="product") {{ product.name }}
        
  template(v-slot:main)
    div.product-builder__container.bg-neutral-a.flex-grow-1(v-if="product")
      div.rounded-b-xxl.bg-white.py-3.px-5
        EatImage.product-builder__image.rounded(
          :src="getImageSrc(product)"
          :lazyLoading="true"
        )
        div.card-opened__text.d-flex.flex-column.justify-center.px-3
          div.text-body-1.font-weight-medium.pb-1.pt-3 {{ product.name }}
          div.text-body-1.font-weight-regular(v-if="!hidePrices && product.price !== 0") {{ formatPrice(product.price) }}
          div.text-caption(v-if="product.isHarmful") {{ $t("order.product.isHarmful") }}
        div.px-3.py-4.text-caption {{ product.description }}

      ProductBuilder.mt-1.pb-15.mb-15(:readonly="readonly")

      div.float-btn__container(v-if="!readonly")
        div.float-btn__background.rounded.px-7.pb-5.pt-2.d-flex.flex-column
          div.text-h6.font-weight-regular.ms-auto.mb-2(v-if="!hidePrices") {{ $t("cart.total", { price: formatPrice(configurationTotal)}) }}
          div.float-btn__controls
            EatStepper(v-if="!readonly" @increase="changeConfigurationQuantity(+1)" :value="quantity || 0" @decrease="changeConfigurationQuantity(-1)" :contractable="false")
            EatFloatBtn(@click="onProductBuilderSubmit") 
              div.text-truncate {{ $t("productBuilder.addToCart") }} 
</template>

<script setup lang="ts">
import FullPageDialog from "@/layout/partials/FullPageDialog.vue";
import ProductBuilder from "@/components/menuProduct/ProductBuilder.vue";
import type { CartItem } from "@/models/Cart";
import { computePrice, isValid, type Product } from "@/models/Product";
import { useApp } from "@/store/app";
import { formatPrice } from "@/utils/formatPrice";
import { hasDefaultConfiguration } from "@/utils/product";
import { ref, computed } from "vue";
import { useCartItems } from "@/store/cart/cartItems";
import { setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import useProductDetail from "@/store/order/productDetail";
import { useI18n } from "vue-i18n";
import { getQueryParam } from "@/utils/queryParams";

defineProps({
  readonly: {
    type: Boolean,
    default: false
  }
});

const i18n = useI18n();

const { product, configuration, quantity, reset } = useProductDetail();
const { location } = useApp();
const { addToCart, flattenedProductsInCart } = useCartItems();
const hidePrices = ref(getQueryParam("hidePrices") ? true : false);

const showDialog = computed(() => !!product.value);

const hasProductDefaultConfiguration = ref(
  product.value ? hasDefaultConfiguration(product.value) : false
);

const addProductToCart = () => {
  if (!configuration.value) return;
  addToCart({
    product: product.value,
    quantity: quantity.value,
    configuration: {
      mandatoryComponents: configuration.value.mandatoryComponents,
      addedComponents: configuration.value.addedComponents,
      removedComponents: configuration.value.removedComponents,
      modules: configuration.value.modules,
      upsellingModules: configuration.value.upsellingModules,
      articleAttributes: configuration.value.articleAttributes
    }
  } as CartItem);
};

const onCloseDialog = () => reset();

const onProductBuilderSubmit = () => {
  if (!product.value || !configuration.value) return;
  if (
    isValid(configuration.value, product.value) ||
    (!product.value.isModular && hasProductDefaultConfiguration.value)
  ) {
    addProductToCart();
    reset();
  } else {
    setSnackbar(i18n.t("productBuilder.checkMandatoryFields").toString(), TypeSnackbar.WARNING);
  }
};

const changeConfigurationQuantity = (amount: number) => {
  if (!product.value?.id || !configuration.value) return;
  const cartQuantity = flattenedProductsInCart.value[product.value.id] || 0;
  const availableQuantity = product.value.warehouseItem?.availableStockQuantity || Infinity;
  const newQuantity = (quantity.value || 0) + amount;
  if(newQuantity < 0) return;
  else if(cartQuantity + newQuantity <= availableQuantity) {
    quantity.value = newQuantity;
  } else {
    setSnackbar(
      i18n.t("order.product.maxAvailableStockQuantityReached").toString(),
      TypeSnackbar.WARNING
    );
  }
};

const configurationTotal = computed(() => {
  if (!product.value || !configuration.value || !quantity.value) return 0;
  return quantity.value * computePrice(product.value, configuration.value);
});

const getImageSrc = (product: Product) => {
  return product.images && product.images.length ? product.images[0] : location.value?.logoUrl;
};
</script>

<style lang="scss" scoped>
.product-builder__container {
  padding-bottom: 126px;

  .product-builder__image {
    width: 100%;
  }

  .float-btn__container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    .float-btn__background {
      width: 100%;
      backdrop-filter: blur(2px);
      .float-btn__controls {
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
