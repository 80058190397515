<template lang="pug">
div.card.d-flex.overflow-hidden.px-5.py-3
  div.card__text.mr-auto.pe-4.d-flex.flex-column.justify-center.flex-shrink-1(@click="openProductDetail")
    div.text-body-1.font-weight-medium.pb-1.text-truncate {{ product.name }}
    div.card__description.w-100.pb-2.text-caption.text-truncate(v-if="product.description" style="color: #6b8093;") {{ product.description }}
    div.font-weight-regular(v-if="!hidePrices && product.price" :class="!isAvailableInStock(product) ? 'text-error text-caption' : ''") {{ isAvailableInStock(product) ? formatPrice(product.price) : $t("order.product.notAvailableInStock") }}
    div.text-caption(v-if="product.isHarmful") {{ $t("order.product.isHarmful") }}

  div.card__image-container.position-relative.flex-shrink-0.rounded.overflow-hidden
    EatImage.card__image(:src="getImageSrc(product)" :lazyLoading="true")
    div.absolute-cover
      div.absolute-tl.pa-2(v-if="product.allergens")
        div.text-caption.text-white.rounded-sm.border.px-2.py-1(style="background-color: #0000006f; backdrop-filter: blur(10px);") {{ $t('productBuilder.allergens') }}
      button.absolute-tr.pa-2(v-if="false")
        EatIcon(icon="$heart").text-white
      div.absolute-blr.px-5.pb-2
        EatStepper(small @increase="changeQuantity(+1)" :value="quantityProductInCart" @decrease="changeQuantity(-1)" v-if="!readonly")
</template>

<script setup lang="ts">
import type { Product } from "@/models";
import type { CartItem } from "@/models/Cart";
import { useApp } from "@/store/app";
import { formatPrice } from "@/utils/formatPrice";
import { hasDefaultConfiguration, isNeatConfiguration } from "@/utils/product";
import { type PropType, ref, computed } from "vue";
import { useCartItems } from "@/store/cart/cartItems";
import useProductDetail from "@/store/order/productDetail";
import { setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import { getQueryParam } from "@/utils/queryParams";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true
  },
  readonly: {
    type: Boolean,
    required: false,
    default: false
  }
});

const router = useRouter();
const { location } = useApp();
const i18n = useI18n();
const {
  removeFromCart,
  getSameProductCartItems,
  addToCart,
  flattenedProductsInCart
} = useCartItems();
const { setProduct, reset, configuration, quantity } = useProductDetail();
const hidePrices = ref(getQueryParam("hidePrices") ? true : false);

const hasProductDefaultConfiguration = ref(hasDefaultConfiguration(props.product));

const isAvailableInStock = (product: Product) => {
  return !product.warehouseItem || product.warehouseItem.availableStockQuantity > 0;
};

const isMaxQuantityReached = () => {
  const cartQuantity = flattenedProductsInCart.value[props.product.id] || 0;
  const availability = props.product.warehouseItem?.availableStockQuantity;
  return availability ? cartQuantity >= availability : false;
};

const openProductDetail = () => {
  if (!isAvailableInStock(props.product)) {
    return setSnackbar(
      i18n.t("order.product.notAvailableInStock").toString(),
      TypeSnackbar.WARNING
    );
  }
  if (isMaxQuantityReached()) {
    return setSnackbar(
      i18n.t("order.product.maxAvailableStockQuantityReached").toString(),
      TypeSnackbar.WARNING
    );
  }

  setProduct(props.product);
};

const addCartItemToCart = () => {
  setProduct(props.product);
  if(!configuration.value || quantity.value === undefined) return;
  addToCart({
    product: props.product,
    quantity: quantity.value,
    configuration: {
      mandatoryComponents: configuration.value.mandatoryComponents,
      addedComponents: configuration.value.addedComponents,
      removedComponents: configuration.value.removedComponents,
      modules: configuration.value.modules,
      upsellingModules: configuration.value.upsellingModules,
      articleAttributes: configuration.value.articleAttributes
    }
  } as CartItem);
  reset();
};

const changeCartItemQuantity = (cartItem: CartItem, change: number) => {
  if (change > 0) {
    if (!props.product.warehouseItem) {
      cartItem.quantity += change;
    } else {
      if (cartItem.quantity + change <= props.product.warehouseItem.availableStockQuantity) {
        cartItem.quantity += change;
      } else {
        setSnackbar(
          i18n.t("order.product.maxAvailableStockQuantityReached").toString(),
          TypeSnackbar.WARNING
        );
      }
    }
  } else {
    (cartItem.quantity + change <= 0) ? removeFromCart(cartItem) : (cartItem.quantity += change);
  }
};

const cartItems = computed(() => getSameProductCartItems(props.product));

const quantityProductInCart = computed(() => {
  return cartItems.value && cartItems.value.length
    ? cartItems.value.reduce((sum, item) => {
        return sum + item.quantity;
      }, 0)
    : 0;
});

const changeQuantity = (change: number) => {
  if (props.product.isModular || !hasProductDefaultConfiguration.value) {
    if (cartItems.value && cartItems.value?.length === 0) return openProductDetail();
    else return router.push({ name: "cart" });
  }

  if (cartItems.value?.length === 0 && change > 0) {
    if (isAvailableInStock(props.product)) {
      addCartItemToCart();
    } else {
      setSnackbar(i18n.t("order.product.notAvailableInStock").toString(), TypeSnackbar.WARNING);
    }
  } else if (cartItems.value?.length === 1) {
    const cartItem = cartItems.value[0];
    if (isNeatConfiguration(cartItem.configuration))
      return changeCartItemQuantity(cartItem, change);
    else return router.push({ name: "cart" });
  } else if (cartItems.value && cartItems.value?.length > 1) {
    router.push({ name: "cart" });
  }
};

const getImageSrc = (product: Product) => {
  return product.images && product.images.length
    ? product.images[0]
    : location.value?.logoUrl || "";
};
</script>

<style lang="scss" scoped>
$max-height: 140px;
$image-width: 116px;
.card {
  height: $max-height;
  max-height: $max-height;
  .card__text {
    width: calc(100% - #{$image-width});
    min-height: $image-width;
    cursor: pointer;
  }

  .card__image-container {
    height: $image-width;
    width: $image-width;
    .card__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
