<template lang="pug">
EatUl
  EatLi.d-flex.justify-space-between.align-center(v-for="option, i in attribute.valueSet" :key="option+i" :class="{ selected: localValue && option === localValue.value && !readonly }" @click="!readonly ? onOptionClick(option) : null")
    div {{ option }}
    EatIcon(:icon="localValue && option === localValue.value && !readonly ? '$check' : '$plus'" :size="18" :class="{ 'text-primary': localValue && option === localValue.value && !readonly }")
</template>

<script setup lang="ts">
import type { ArticleAttributeValue, StringArticleAttribute } from "@/models/Product";
import { computed, type PropType } from "vue";

const props = defineProps({
  readonly: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: Object as PropType<{ name: string; value: ArticleAttributeValue } | null>,
    required: true
  },
  attribute: {
    type: Object as PropType<StringArticleAttribute>,
    required: true
  }
});

const emit = defineEmits(["update:modelValue"]);

const localValue = computed({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value)
});

const onOptionClick = (option: string) => {
  localValue.value = { name: props.attribute.name, value: option };
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: var(--bgselected);
  color: var(--clr-primary) !important;
}
</style>
