import type { Product } from "@/models";
import { isEmptyObject } from ".";
import type { ProductConfiguration } from "@/models/Product";

export function hasDefaultConfiguration(product: Product) {
  const hasMandatoryArticleAttributes =
    product.articleAttributes?.some(attribute => attribute.isMandatory) ||
    (product.mandatoryComponentsGroups && product.mandatoryComponentsGroups.length > 0);
  return !hasMandatoryArticleAttributes;
}

export function isNeatConfiguration(config: ProductConfiguration) {
  return (
    !config.addedComponents.length &&
    !config.removedComponents.length &&
    isEmptyObject(config.articleAttributes) &&
    isEmptyObject(config.modules) &&
    isEmptyObject(config.upsellingModules)
  );
}
