<template lang="pug">
div(v-if="product && configuration")
  IngredientsTagsSection(
    v-if="(product.tags && product.tags.length) || (product.standardComponents && product.standardComponents.length) || product.allergens || (app && app.pdfFileAllergens)"
    :product="product")

  MandatoryComponentsSelector(
    v-if="product.mandatoryComponentsGroups && product.mandatoryComponentsGroups.length" 
    :components="product.mandatoryComponentsGroups" 
    :hidePrices="hidePrices" 
    :readonly="readonly" 
    v-model="configuration.mandatoryComponents")   

  RemovableComponentsSelector(
    v-if="product.removableComponents.length" 
    :components="product.removableComponents" 
    :hidePrices="hidePrices" 
    :readonly="readonly" 
    v-model="configuration.removedComponents")

  AddableComponentsSelector( 
    v-if="product.addableComponents.length" 
    :components="product.addableComponents" 
    :hidePrices="hidePrices" 
    :readonly="readonly" 
    v-model="configuration.addedComponents"
  )

  ModulesProductsSelector(
    v-if="product.isModular" 
    :product="product" 
    :modules="configuration.modules" 
    @input-modules="configuration.modules = $event" 
    :upsellingModules="configuration.upsellingModules" 
    @input-upselling-modules="configuration.upsellingModules = $event" 
    :isCartModifier="isCartModifier"
    :hide-prices="hidePrices" 
    :readonly="readonly")

  ArticleAttributesSelectors(
    v-if="product.articleAttributes && product.articleAttributes.length && !readonly" 
    :product="product" 
    v-model="configuration")
</template>

<script setup lang="ts">
import { type ProductConfiguration } from "@/models/Product";
import IngredientsTagsSection from "@/components/menuProduct/selectors/IngredientsTagsSection.vue";
import MandatoryComponentsSelector from "@/components/menuProduct/selectors/MandatoryComponentsSelector.vue";
import StandardComponentsList from "@/components/menuProduct/selectors/StandardComponentsList.vue";
import RemovableComponentsSelector from "@/components/menuProduct/selectors/RemovableComponentsSelector.vue";
import AddableComponentsSelector from "@/components/menuProduct/selectors/AddableComponentsSelector.vue";
import ModulesProductsSelector from "@/components/menuProduct/selectors/ModulesProductsSelector.vue";
import ArticleAttributesSelectors from "@/components/menuProduct/selectors/ArticleAttributesSelectors.vue";
import { useApp } from "@/store/app";
import { ref } from "vue";
import useProductDetail from "@/store/order/productDetail";
import { getQueryParam } from "@/utils/queryParams";

export interface BuilderProductConfiguration extends ProductConfiguration {
  valid?: boolean;
  quantity: number;
}

defineProps({
  readonly: {
    type: Boolean,
    default: false
  },
  isCartModifier: {
    type: Boolean,
    default: false
  }
});

const { app } = useApp();
const hidePrices = ref(getQueryParam("hidePrices") ? true : false);
const { product, configuration } = useProductDetail();
</script>
