<template lang="pug">
div(v-if="components.length")
  RoundedContainer(v-for="group, i in components" :key="i" :subtitle="readonly ? '' : $t('productBuilder.mandatoryComponents.subtitle')" :title="group.name" :horizontal-padding="false" :stickyTitle="true")
    ProductBuilderSelect(:options="group.components" :selected="selected[i]" @change="onSelectChange(i, $event)" :readonly="readonly")
      template(v-slot:option="{ option }")
        div.d-flex.justify-space-between(v-if="isMandatoryComponent(option)")
          div {{ option.name }}
          div(v-if="!hidePrices")
            template(v-if="option.addingPrice != 0")
              span {{ option.addingPrice > 0 ? "+" : "" }}{{ formatPrice(option.addingPrice) }}
            template(v-else)
              span {{ $t('productBuilder.free') }}
</template>

<script setup lang="ts">
import { computed, type PropType, type Ref } from "vue";
import ProductBuilderSelect from "@/components/menuProduct/selectors/ProductBuilderSelect.vue";
import type { MandatoryComponent, MandatoryComponentsGroup } from "@/models/Product";
import { formatPrice } from "@/utils/formatPrice";
import useProductDetail from "@/store/order/productDetail";
import { setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import { useI18n } from "vue-i18n";

const props = defineProps({
  components: {
    type: Array as PropType<MandatoryComponentsGroup[]>,
    required: true
  },
  readonly: {
    type: Boolean,
    required: true
  },
  hidePrices: {
    type: Boolean,
    required: true
  },
  modelValue: {
    type: Array as PropType<MandatoryComponent[]>,
    required: true
  }
});

const emit = defineEmits(["update:modelValue"]);

const isMandatoryComponent = (component: any): component is MandatoryComponent => true;

const i18n = useI18n();
const localValue = computed({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value)
});

const selected: Ref<number[][]> = computed(() => {
  return props.components.reduce(
    (selectedGroups: number[][], group: MandatoryComponentsGroup, i): number[][] => {
      const selectedGroup = group.components.reduce(
        (selected: number[], component: MandatoryComponent, i: number) => {
          if (
            props.modelValue.some(
              configComponent => configComponent.productId === component.productId
            )
          ) {
            selected.push(i);
          }
          return selected;
        },
        []
      );
      selectedGroups.push(selectedGroup);
      return selectedGroups;
    },
    []
  );
});

const onSelectChange = (groupIndex: number, index: number) => {
  const { canAddToConfiguration } = useProductDetail();
  const newSelected = props.components[groupIndex].components[index];
  const currentGroupSelected = selected.value[groupIndex];
  if (currentGroupSelected.includes(index)) {
    if (!canAddToConfiguration(-newSelected.addingPrice)) {
      setSnackbar(
        i18n.t("productBuilder.negativePriceNotAllowed").toString(),
        TypeSnackbar.WARNING
      );
      return;
    }
    currentGroupSelected.splice(currentGroupSelected.indexOf(index), 1);
  } else {
    if (!canAddToConfiguration(newSelected.addingPrice)) {
      setSnackbar(
        i18n.t("productBuilder.negativePriceNotAllowed").toString(),
        TypeSnackbar.WARNING
      );
      return;
    }
    currentGroupSelected.push(index);
  }
  localValue.value = props.components.reduce(
    (
      components: MandatoryComponent[],
      group: MandatoryComponentsGroup,
      groupIndex: number
    ): MandatoryComponent[] => {
      components.push(
        ...group.components.filter((component, i) => selected.value[groupIndex].includes(i))
      );
      return components;
    },
    []
  );
};
</script>
