<template lang="pug">
div
  div.sticky-container.d-flex.flex-column          
    div.categories-slider.d-flex.bg-white.border-b.flex-shrink-0(v-scrollable="false")
      button.flex-grow-0.px-4.d-flex.align-center(v-if="!isLoading" @click="onOpenDialog")
        EatIcon(icon="$hamburger")
        
      div.flex-grow-1.categories-slider.d-flex( v-scrollable="true" :id="`${saletype}-category-slider-scrollable`")
        div.categories-slider.d-flex.py-4.px-4.gap-10(v-if="isLoading" v-scrollable="true")
          div.bg-skeleton.skeleton-text-body-1.w-9.flex-shrink-0(v-for="k in skeletonCards" :key="k" v-if="isLoading")
        button.category-slider__btn.py-4.px-4(
          v-else
          v-for="category, j in renderedCategories" :key="category.id" 
          :id="`btn-${saletype}-${category.id}`" 
          :class="{ active: isActiveCategory(category.id), 'me-3': j === renderedCategories.length - 1 }" 
          @click="scrollToCategory(category.id)"
        ) {{ category.name }}

  div.flex-shrink-0.flex-grow-1.d-flex.flex-column
    div.px-4.pt-5.pb-6.bg-white.mt-1(v-if="tags && tags.length")
      button.select.rounded-lg.overflow-hidden(:class="isActive ? 'active text-primary border border-clr-primary' : 'border'" @click="showTags = !showTags") 
        div.d-flex.align-center.ps-4.pe-5.py-3(:class="{  bgselected: isActive } ")
          EatIcon.mr-2(:icon="showTags ? '$chevronUp' : '$chevronDown'" :size="14" :class="{ 'text-primary' : isActive }") 
          div(style="line-height: 16px;") {{ $t('order.tagsSelector.filters') }}
      transition(name="fade")
        div.d-flex.flex-wrap.mt-4(v-if="showTags") 
          button.tag-toggle.mb-2.me-2(v-for="tag, i in tags" :key="`tag-${i}`"  @click="toggleSelectedTag(tag)" :class="isSelected(tag) ? 'bgselected' : ''") 
            div.pa-2.rounded-sm(:class="isSelected(tag) ? 'text-primary border border-clr-primary' : 'border'") {{ tag }}

    div(v-if="isLoading")
      EatUl.skeleton-rounded-container.rounded-xxl.bg-white.overflow-hidden.mt-1.py-3.px-0
        div.skeleton-rounded-container__title.bg-skeleton.ma-5
        EatLi.skeleton-card.d-flex.px-5.py-3(v-for="i in skeletonCards" :key="i+10")
          div.skeleton-card__text.flex-grow-1.d-flex.flex-column.justify-center
            div.skeleton-card__title.bg-skeleton.mb-3
            div.skeleton-card__subtitle.bg-skeleton
          div.skeleton-card__image.bg-skeleton.rounded

    div.flex-grow-1.d-flex.flex-column(v-else-if="renderedCategories && renderedCategories.length")
      TransitionGroup(name="fade")
        RoundedContainer.mb-1.overflow-hidden(
          v-for="category, k in renderedCategories" 
          :key="category.id"
          :top-flat="k === 0" 
          :horizontal-padding="false"
          :title="category.name"  
          :subtitle="getCategorySubtitle(category)"
          :id="`${saletype}-${category.id}`" 
        )
          ProductCard(v-for="product, i in category.products" :key="product.id" :product="product" :class="{'border-t': i > 0}" :readonly="readonly")
    
    Transition(name="fade" tag="div" v-else)
      div.products__placeholder.bg-white.rounded-b-xxl.mb-8.pt-8.d-flex.justify-center
        div {{ $t("order.categoriesSection.noProducts") }}

    FullPageDialog(v-model="showDialog" :scrollable="true")
      template(v-slot:header)
        div.d-flex.px-4.py-2.w-100.gap-4.align-center
          EatIconBtn(:size="24" @click="onCloseDialog" icon="$close")
          span.text-h6.font-weight-medium  {{ $t("order.categories") }}
      template(v-slot:main)
        CategoriesSelector(:saletype="saletype" @close="onCloseDialog")
</template>

<script setup lang="ts">
import { Saletype } from "@/models/App";
import { useCategoriesScrollSync } from "@/store/order/scrollManager";
import { computed, ref, watch, onMounted, type PropType } from "vue";
import CategoriesSelector from "@/views/order/partials/CategoriesSelector.vue";
import FullPageDialog from "@/layout/partials/FullPageDialog.vue";
import ProductCard from "@/components/menuProduct/ProductCard.vue";
import type { Category } from "@/models";
import { useMenu, useMenuFilters } from "@/store/menu";
import { useCartItems } from "@/store/cart/cartItems";
import { useI18n } from "vue-i18n";

const props = defineProps({
  readonly: {
    type: Boolean,
    default: false
  },
  saletype: {
    type: String as PropType<Saletype>,
    required: true
  }
});

const i18n = useI18n();
const skeletonCards = ref([...Array(15).keys()]);

const { tags, filteredCategories, fetchMenu } = useMenu(props.saletype);
const { selectedTags, toggleSelectedTag } = useMenuFilters();
const renderedCategories = ref<Category[]>([]);
const isLoading = ref(false);
  
const { scrollToCategory, isActiveCategory } = useCategoriesScrollSync(
  renderedCategories,
  props.saletype
);

onMounted(async () => {
  isLoading.value = true;
  await fetchMenu(500);
  isLoading.value = false;
});

watch(filteredCategories, () => (renderedCategories.value = [...filteredCategories.value]), {
  immediate: true
});

const showTags = ref(false);
const isActive = computed(() => !!selectedTags.value.length);

const isSelected = (tag: string) => {
  return selectedTags.value.some(selectedTag => selectedTag === tag);
};

const getCategorySubtitle = (category: Category) => {
  if (!category.productionRate) return "";

  const { isCategoryOverloaded } = useCartItems();
  if (isCategoryOverloaded(category)) {
    return i18n.t("order.categoriesSection.overloadedCategories");
  } else {
    return i18n.t("order.categoriesSection.productAvailability", category.productionRate);
  }
};

const showDialog = ref(false);
const onOpenDialog = () => {
  showDialog.value = true;
};
const onCloseDialog = (category?: string | MouseEvent) => {
  if (category && !(category instanceof MouseEvent)) scrollToCategory(category);
  showDialog.value = false;
};
</script>

<style lang="scss" scoped>
.sticky-container {
  position: sticky;
  top: calc(var(--saletype-summary-height, 0px) - 1px);
  left: 0;
  z-index: 2;
  will-change: transform;
}
.tag-toggle {
  cursor: pointer;
}
.categories-slider {
  min-height: 57px;
  height: 57px;
  flex-wrap: nowrap;
  .category-slider__btn {
    position: relative;
    min-width: max-content;
    &.active::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: 3px solid black;
    }
  }
}

.skeleton-category {
  height: 24px;
  width: 100px;
}

$max-height: 140px;
$image-width: 116px;

.fade-move,
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.skeleton-rounded-container__title {
  height: 24px;
  width: 50%;
}
.skeleton-card {
  height: $max-height;
  .skeleton-card__title {
    height: 20px;
    width: 40%;
  }
  .skeleton-card__subtitle {
    height: 16px;
    width: 60%;
  }
  .skeleton-card__image {
    width: $image-width;
    height: $image-width;
  }
}

.products__placeholder {
  height: calc(var(--shell-height) - 450px);
}
</style>
