import type { Product } from "@/models";
import type { CartItem } from "@/models/Cart";
import { computePrice, type ProductConfiguration } from "@/models/Product";
import { ref } from "vue";

const product = ref<Product>();
const configuration = ref<ProductConfiguration>();
const quantity = ref<number|undefined>();

const useProductDetail = () => {
  const initConfiguration = () => {
    configuration.value = {
      addedComponents: [],
      mandatoryComponents: [],
      removedComponents: [],
      articleAttributes: {},
      modules: {},
      upsellingModules: {},
    };
    quantity.value = 1;

    if (product.value?.isModular) {
      for (const module of product.value?.modules) {
        if (module.name in configuration.value.modules) continue;
        const instantMenu = module.products.filter(product => product.instantMenu);
        configuration.value.modules[module.name] = instantMenu.length ? instantMenu : [];
        configuration.value.upsellingModules[module.name] = [];
      }
    }
    if (product.value?.articleAttributes && product.value?.articleAttributes.length) {
      for (const attribute of product.value?.articleAttributes) {
        if (attribute.attributeTypeId in configuration.value.articleAttributes) continue;
        let value: boolean | number | string = "";
        if (attribute.fieldType === "number") value = attribute.isMandatory ? 1 : 0;
        if (attribute.fieldType === "string") value = "";
        if (attribute.fieldType === "boolean") value = attribute.isMandatory ? true : false;
        configuration.value.articleAttributes[attribute.attributeTypeId] = {
          name: attribute.name,
          value: value
        };
      }
    }
  };

  const canAddToConfiguration = (price: number) => {
    if (!product.value || !configuration.value) throw new Error("Prodotto non selezionato");
    const configurationPrice = computePrice(product.value, configuration.value);
    return configurationPrice + price >= 0;
  };

  const setProduct = (newProduct: Product) => {
    product.value = newProduct;
    initConfiguration();
  };

  const setConfigurationFromCartItem = (cartItem: CartItem) => {
    product.value = cartItem.product;
    configuration.value = cartItem.configuration;
  };

  const reset = () => {
    product.value = undefined;
    configuration.value = undefined;
    quantity.value = undefined;
  };

  return {
    product,
    configuration,
    quantity,
    initConfiguration,
    reset,
    setProduct,
    setConfigurationFromCartItem,
    canAddToConfiguration
  };
};

export default useProductDetail;
